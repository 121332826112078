import { render, staticRenderFns } from "./AreaComponents.vue?vue&type=template&id=61f7c8ce&scoped=true&"
import script from "./AreaComponents.vue?vue&type=script&lang=js&"
export * from "./AreaComponents.vue?vue&type=script&lang=js&"
import style0 from "./AreaComponents.vue?vue&type=style&index=0&id=61f7c8ce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f7c8ce",
  null
  
)

export default component.exports