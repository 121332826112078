<template>
  <el-form
    label-position="right"
    :model="formData"
    ref="form"
    :rules="rules"
    label-width="80px"
  >
    <slot name="content"></slot>
    <el-form-item>
      <div style="text-align: center; margin-top: 50px">
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button @click="cancelForm">取消</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "FormComponents",
  props: {
    msg: String,
    formData: Object,
    rules: {
      type: Object,
      default: null,
    },
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        //this.$confirmDialog("确定要进行当前操作吗?", () => {
        this.$emit("submitForm");
        //});
      });
    },
    cancelForm() {
      this.$emit("cancelForm");
    },
    validateField(field) {
      console.log('validateField',field);
      this.$refs.form.validateField(field);
    },
  },
};
</script>