/*
* 返回OSS文件上传路径
*/
export function uploaderPath() {
    let baseURL = process.env.NODE_ENV !== "development" ? process.env.VUE_APP_API : '';
    return baseURL.concat('/v1/oss/upload');
}

/**
 * 上传身份证证件正面信息
 * @returns 
 */
export function uploadIDCardFrontForHuiFu(){
    let baseURL = process.env.NODE_ENV !== "development" ? process.env.VUE_APP_API : '';
    return baseURL.concat('/v1/oss/uploadIDCardFrontForHuiFu');
}


/**
 * 上传身份证证件反面信息
 * @returns 
 */
 export function uploadIDCardContraryForHuiFu(){
    let baseURL = process.env.NODE_ENV !== "development" ? process.env.VUE_APP_API : '';
    return baseURL.concat('/v1/oss/uploadIDCardContraryForHuiFu');
}


/**
 * 上传银行卡证件正面信息
 * @returns 
 */
 export function uploadBankImageForHuiFu(){
    let baseURL = process.env.NODE_ENV !== "development" ? process.env.VUE_APP_API : '';
    return baseURL.concat('/v1/oss/uploadBankFrontForHuiFu');
}

/**
* 上传安卓应用程序
* @returns 
*/
export function uploadVersion(){
    let baseURL = process.env.NODE_ENV !== "development" ? process.env.VUE_APP_API : '';
    return baseURL.concat('/v1/obs/upload');
}