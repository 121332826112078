import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const TripDictionaryApi = {
    search: (data) => AppHttpKit.postJSON(`/v1/tripDictionarylist`,data),//这里必须写清楚接口的注释说明
    saveTripDictionary: (data) => AppHttpKit.postJSON(`/v1/saveTripDictionary`,data),
    queryTripDictionaryById: (data) => AppHttpKit.postJSON(`/v1/queryTripDictionaryById`,data),
    updateTripDictionary: (data) => AppHttpKit.postJSON(`/v1/updateTripDictionary`,data),
    deleteTripDictionary: (data) => AppHttpKit.postJSON(`/v1/deleteTripDictionary`,data),
    batchDeleteTripDictionary:(data) => AppHttpKit.postJSON(`/v1/batchDeleteTripDictionary`,data),
    queryDriverLicense:(data) => AppHttpKit.postJSON(`/v1/queryDriverLicense`,data),
}
export default TripDictionaryApi;
