import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const ocrApi = {
    recognizeIdCard: (data) => AppHttpKit.postJSON(`/v1/appDriver/recognizeIdCard`, data),//OCR识别--身份证信息
    recognizeBankCard: (data) => AppHttpKit.postJSON(`/v1/appDriver/recognizeBankCard`, data),//OCR识别--银行卡信息
    recognizeDriverLicense: (data) => AppHttpKit.postJSON(`/v1/appDriver/recognizeDriverLicense`, data),//OCR识别--驾驶证信息
    recognizeVehicleLicense: (data) => AppHttpKit.postJSON(`/v1/appDriver/recognizeVehicleLicense`, data),//OCR识别--行驶证信息
}
export default ocrApi;