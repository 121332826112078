<!--全国省市级联-->
<template>
  <el-cascader
    v-model="selectArea"
    :options="areaList"
    :props="props"
    @change="handleChange"
  ></el-cascader>
</template>

<script>
export default {
  props: {
    defaultArea: {
      type: Array,
      default: ()=>[],
    },
    tickCount: {
      type: Number,
      default: 3,
    },
  },
  watch:{
    defaultArea(newVal){
      console.log('defaultArea',newVal);
      this.selectArea=newVal;
    }
  },
  data() {
    return {
      selectArea:[],
      areaList: [],
      props: {
        expandTrigger: "hover",
        label: "name",
        value: "no",
        children: "childNodeList",
      },
    };
  },
  async created() {
    let obj;
    if (this.$store.state.areaList.length == 0) {
      obj =await this.$store.dispatch("getAreaList");
    } else {
      obj = this.$store.state.areaList;
    }
    let areaList=JSON.parse(JSON.stringify(obj));
    if (this.tickCount < 3) {
      for (let i = 0; i < areaList.length; i++) {
        const element = areaList[i];
        if (this.tickCount == 1) {
          element.childNodeList = undefined;
        } else if (this.tickCount == 2 && element.childNodeList) {
          for (let j = 0; j < element.childNodeList.length; j++) {
            element.childNodeList[j].childNodeList = undefined;
          }
        }
      }
    }
    this.areaList = areaList;
    console.log(this.selectArea);
  },
  methods: {
    handleChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style scoped>
.el-cascader {
  width: 100%;
}
</style>