import * as AppHttpKit from '@/utils/http/AppHttpKit';
import * as apphttp from '@/utils/http/apphttp'
/**
 * 以下是完成API接口调用的定义规范
 */
const TripDriverApi = {
    search: (data) => AppHttpKit.postJSON(`/v1/tripDriverlist`, data),//这里必须写清楚接口的注释说明
    saveTripDriver: (data) => AppHttpKit.postJSON(`/v1/saveTripDriver`, data),
    updateTripDriver: (data) => AppHttpKit.postJSON(`/v1/updateTripDriver`, data),
    deleteTripDriver: (data) => AppHttpKit.postJSON(`/v1/deleteTripDriver`, data),
    batchDeleteTripDriver: (data) => AppHttpKit.postJSON(`/v1/batchDeleteTripDriver`, data),
    genDriverCode: (data) => AppHttpKit.get(`v1/genDriverCode/${data}`),
    searchCar: (data) => AppHttpKit.postJSON(`/v1/searchCar`, data),
    queryTripDriverById: (data) => AppHttpKit.postJSON(`/v1/queryTripDriverById`, data),//即将废弃
    queryDriverByNo: (data) => AppHttpKit.postJSON(`/v1/queryDriverByNo`, data),
    findDriverAccount: (data) => AppHttpKit.get(`/v1/driverAccount/findDriverAccount/${data}`),
    modifyDriverAccount: (data) => AppHttpKit.postJSON('/v1/driverAccount/modifyDriverAccount', data),
    saveDriverAccount: (data) => AppHttpKit.postJSON('/v1/driverAccount/openUserBusiness', data),
    findDriverAccountApplyList: (data) => AppHttpKit.postJSON('/v1/driverAccount/findDriverAccountApplyList', data),
    queryTripCompanyByNo: (data) => AppHttpKit.get(`/v1/queryTripCompanyByNo/${data}`),
    batchDownloadDriverQrCode: (data) => apphttp.postBlob(`/v1/batchDownloadDriverQrCode`, data),
    auditDriver: (data) => AppHttpKit.postJSON('/v1/auditDriver', data),//审核
    findAccountApplyStatusSelect: () => AppHttpKit.postJSON('/v1/findAccountApplyStatusSelect'),//获取司机账户审批状态下拉框
    findDriverApplyList: (data) => AppHttpKit.postJSON('/v1/findDriverApplyList', data),//获取司机注册审批流水
    exportDriverList: (data) => apphttp.postBlob(`/v1/exportDriverList`, data),//导出司机数据
    frozenDriver: (data) => AppHttpKit.postJSON(`/v1/frozenDriver`, data),//司机冻结、解冻接口
    findDriverOperationRecordList: (data) => AppHttpKit.postJSON(`/v1/findDriverOperationRecordList`, data),//获取操作司机记录
}
export default TripDriverApi;
