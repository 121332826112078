<template>
  <el-form :model="tripDriver" ref="form" :rules="rules">
    <div class="head"><i></i>司机信息</div>
    <div class="uploaderList">
      <div class="uploader">
        <el-form-item label="身份证国徽面" prop="idCardFront">
          <div v-if="tripDriver.idCardFront" class="avatar">
            <img @click="tripDriverImgShow(idCardFrontImage || tripDriver.idCardFront)"
              :src="idCardFrontImage || tripDriver.idCardFront" />
            <span class="el-upload-list__item-delete" @click="handleIdCardFrontRemove">
              <i class="el-icon-delete"></i>
            </span>
          </div>
          <el-upload v-else class="avatar-uploader" :action="uploadIDCardFrontForHuiFu" :show-file-list="false"
            :on-success="handleIdCardFrontSuccess" :before-upload="beforeAvatarUpload">
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <span>上传</span>
          </el-upload>
        </el-form-item>
      </div>
      <div class="uploader">
        <el-form-item label="身份证人像面" prop="idCardContrary">
          <div v-if="tripDriver.idCardContrary" class="avatar">
            <img @click="tripDriverImgShow(idCardContraryImage || tripDriver.idCardContrary)"
              :src="idCardContraryImage || tripDriver.idCardContrary" />
            <span class="el-upload-list__item-delete" @click="handleIdCardContrayRemove">
              <i class="el-icon-delete"></i>
            </span>
          </div>
          <el-upload v-else class="avatar-uploader" :action="uploadIDCardContraryForHuiFu" :show-file-list="false"
            :on-success="handleIdCardContrySuccess" :before-upload="beforeAvatarUpload">
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <span>上传</span>
          </el-upload>
        </el-form-item>
      </div>
      <div class="uploader">
        <el-form-item label="上传驾照" prop="imgPath">
          <div v-if="tripDriver.imgPath" class="avatar">
            <img @click="tripDriverImgShow(preImage || tripDriver.imgPath)" :src="preImage || tripDriver.imgPath" />
            <span class="el-upload-list__item-delete" @click="handleRemove">
              <i class="el-icon-delete"></i>
            </span>
          </div>
          <el-upload v-else class="avatar-uploader" :action="uploadPath" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload1">
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <span>上传</span>
          </el-upload>
        </el-form-item>
      </div>
    </div>
    <el-row>
      <el-col :span="5">
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model.trim="tripDriver.mobile" maxlength="11"
            :disabled="tripDriver.id != null && tripDriver.id != ''" placeholder="请输入" />
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <span class="text color">&nbsp;本手机号用于司机登录打车助手司机端APP，成功添加后司机可通过手机短信验证码登录</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        <el-form-item label="所属车辆" prop="carNumber">
          <el-button type="text" style="float: right" @click="gotoTripCarForm">新增车辆</el-button>
          <el-select v-model.trim="tripDriver.carNumber" filterable remote reserve-keyword placeholder="请输入车牌号"
            :remote-method="remoteLoadPlate" :loading="plateLoading" @focus="focus">
            <el-option v-for="item in carList" :key="item.carNum" :label="item.carNum" :value="item.carNum">
            </el-option>
          </el-select>
          <!-- <el-input v-model="tripDriver.carNumber" maxlength="8" /> -->
        </el-form-item>
      </el-col>
      <el-col :span="5" :offset="1">
        <el-form-item label="初次领证日期" prop="firstApplyTime">
          <el-date-picker type="date" :picker-options="firstApplyTimeOptions" v-model="tripDriver.firstApplyTime"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="5" :offset="1">
        <el-form-item label="准驾车型" prop="carType">
          <el-select v-model="selectCarTypeList" multiple placeholder="请选择">
            <el-option v-for="item in drivingType" :key="item.code" :label="item.value" :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="5" :offset="1">
        <el-form-item prop="validateTime">
          <template slot="label">
            <span>有效期限至</span>
          </template>
          <el-checkbox style="float: right" v-model="isLongTermValid">长期</el-checkbox>
          <el-date-picker type="date" :picker-options="validateTimeOptions" v-model="tripDriver.validateTime"
            value-format="yyyy-MM-dd" v-show="!isLongTermValid" placeholder="选择日期">
          </el-date-picker>
          <el-input readonly v-show="isLongTermValid" value="长期"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        <el-form-item label="姓名" prop="name">
          <el-input v-model.trim="tripDriver.name" maxlength="20" placeholder="请输入" />
        </el-form-item>
      </el-col>
      <el-col :span="5" :offset="1">
        <el-form-item label="籍贯" prop="nativePlace">
          <el-input v-model.trim="tripDriver.nativePlace" maxlength="40" placeholder="请输入" />
        </el-form-item>
      </el-col>
      <el-col :span="5" :offset="1">
        <el-form-item label="出生日期" prop="birthday">
          <el-date-picker type="date" v-model="tripDriver.birthday" :picker-options="birthdayOptions"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="5" :offset="1">
        <el-form-item label="身份证号" prop="idCard">
          <el-input v-model.trim="tripDriver.idCard" maxlength="18" placeholder="请输入" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        <el-form-item prop="cardValidateTime" class="idCardTime">
          <template slot="label">
            <span class="required">身份证有效期</span>
          </template>
          <el-checkbox style="float: right" v-model="idCardIsLongTermValid">长期</el-checkbox>
          <div class="idCardDateTime">
            <el-date-picker v-model="tripDriver.certBeginDate" type="date" placeholder="开始日期" value-format="yyyy-MM-dd"
              :picker-options="idCardBeginOption">
            </el-date-picker>
            <span>至</span>
            <el-date-picker v-model="tripDriver.certEndDate" type="date" placeholder="结束日期" value-format="yyyy-MM-dd"
              :picker-options="idCardEndOption" v-show="!idCardIsLongTermValid">
            </el-date-picker>
            <el-input readonly v-show="idCardIsLongTermValid" value="长期"></el-input>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="5" :offset="1">
        <el-form-item label="联系地址" prop="distinctNo">
          <AreaComponents :defaultArea="selectArea" @change="areaChange" />
        </el-form-item>
      </el-col>
      <el-col :span="5" :offset="1">
        <el-form-item label="详细地址" prop="address">
          <el-input v-model.trim="tripDriver.address" maxlength="100" placeholder="请输入" />
        </el-form-item>
      </el-col>
    </el-row>
    <div class="operate">
      <el-button type="primary" @click="submitForm">提交</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </el-form>
</template>
  <script>
import FormComponents from "@/components/form/FormComponents";
import AreaComponents from "@/components/form/AreaComponents";
import TripDriverApi from "@/api/TripDriverApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
import { uploaderPath, uploadIDCardContraryForHuiFu, uploadIDCardFrontForHuiFu } from "@/utils/UploaderUtils";
import TripDictionaryApi from "@/api/TripDictionaryApi";
import ocrApi from "@/api/ocrApi";
import { mapMutations } from "vuex";
export default {
  name: "SaveTripDriverPage",
  props: {
    msg: String,
  },
  components: {
    FormComponents,
    AreaComponents
  },
  data() {
    let that = this;
    return {
      drivingType: ["A1", "A2", "A3", "B1", "B2", "C1"],
      dialogVisible: false,
      dialogImageUrl: "",
      //是否正在加载车牌号
      plateLoading: true,
      carList: [],
      selectCarTypeList: [],
      tripDriver: {
        carNumber: "",
        imgPath: "",
        idCard: "",
        name: "",
        nativePlace: "",
        address: "",
        birthday: "",
        firstApplyTime: "",
        carType: "",
        validateTime: "",
        mobile: "",
        idCardFront: "",
        idCardFrontFileId: "",
        idCardContrary: "",
        idCardContraryFileId: "",
        cardValidateTime: [],
        certBeginDate: "",
        certEndDate: "",
        certValidityType: "",//有效期类型，1、长期有效，0、非长期有效
        provinceNo: "",
        cityNo: "",
        distinctNo: "",
      },
      isLongTermValid: false,
      idCardIsLongTermValid: false,
      preImage: null,
      idCardFrontImage: null,
      idCardContraryImage: null,
      selectArea: [],
      submitState: false,
      rules: {
        idCardFront: [
          { required: true, message: "请上传身份证国徽面", trigger: "blur" },
        ],
        idCardContrary: [
          { required: true, message: "请上传身份证人像面", trigger: "blur" },
        ],

        // imgPath: [{ required: true, message: "请上传驾照", trigger: "blur" }],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式错误",
            trigger: "blur",
          },
        ],
        carNumber: [
          { required: true, message: "所属车辆不能为空", trigger: "blur" },
          {
            pattern:
              /[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新使]{1}[a-zA-Z]{1}[a-zA-Z0-9]{5,6}/,
            message: "车牌号格式错误",
            trigger: "blur",
          },
        ],
        // firstApplyTime: [
        //   { required: true, message: "初次领证日期不能为空", trigger: "blur" },
        //   {
        //     validator: (rule, value, callback) => {
        //       if (
        //         new Date(that.tripDriver.birthday).getTime() >
        //         new Date(value).getTime()
        //       ) {
        //         callback(new Error("初次申领日期不能大于出生日期"));
        //       } else {
        //         callback();
        //       }
        //     },
        //   },
        // ],
        // carType: [
        //   { required: true, message: "准驾车型不能为空", trigger: "blur" },
        // ],
        // cardValidateTime: [
        //   {
        //     validator: (rule, value, callback) => {
        //       if (that.tripDriver.certBeginDate) {
        //         if (that.isLongTermValid || that.tripDriver.certEndDate) {
        //           callback();
        //         } else {
        //           callback(new Error("身份证有效期限不能为空"));
        //         }
        //       } else {
        //         callback(new Error("身份证有效期限开始时间不能为空"));
        //       }

        //     },
        //   },
        // ],

        name: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
          { min: 2, max: 40, message: "长度应为2-40字符", trigger: "blur" },
        ],
        nativePlace: [
          { required: true, message: "籍贯不能为空", trigger: "blur" },
        ],
        birthday: [
          { required: true, message: "出生日期不能为空", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "身份证号不能为空", trigger: "blur" },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "身份证号格式错误",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "地址不能为空", trigger: "blur" },
          { min: 1, max: 100, message: "长度应为1-100字符", trigger: "blur" },
        ],
        distinctNo: [
          { required: true, message: "联系地址不能为空", trigger: "change" },
        ]
      },
      uploadPath: uploaderPath(),
      uploadIDCardFrontForHuiFu: uploadIDCardFrontForHuiFu(),
      uploadIDCardContraryForHuiFu: uploadIDCardContraryForHuiFu(),
      birthdayOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      firstApplyTimeOptions: {
        disabledDate(time) {
          let flag = false;
          if (that.tripDriver.birthday) {
            flag =
              time.getTime() < new Date(that.tripDriver.birthday).getTime();
          }
          return time.getTime() > Date.now() || flag;
        },
      },
      validateTimeOptions: {
        disabledDate(time) {
          return new Date(time).getTime() < Date.now();
        },
      },
      idCardBeginOption: {
        disabledDate(time) {
          let date = new Date(time).getTime();
          if (that.tripDriver.certEndDate) {
            return date > new Date(that.tripDriver.certEndDate).getTime() || date > Date.now();
          }
          return date > Date.now();
        },
      },
      idCardEndOption: {
        disabledDate(time) {
          return new Date(time).getTime() < new Date(that.tripDriver.certBeginDate).getTime();
        },
      }
    };
  },
  watch: {
    selectCarTypeList(newVal) {
      if (newVal && newVal.length > 0) {
        this.tripDriver.carType = newVal.toString();
      } else {
        this.tripDriver.carType = "";
      }
    }
  },
  created() {
    this.queryDriverLicense();
    this.tripDriver = this.$route.params;
    console.log(this.tripDriver);
    this.isLongTermValid = this.tripDriver.validateTime == "长期";
    if (this.isLongTermValid) this.tripDriver.validateTime = "";
    this.idCardIsLongTermValid = (this.tripDriver.certValidityType == 1)
    if (this.tripDriver.carType)
      this.selectCarTypeList = this.tripDriver.carType.split(',');
    if (!this.tripDriver.id > 0) {
      this.tripDriver.provinceNo = "650000";
      this.tripDriver.cityNo = "650100";
      this.tripDriver.distinctNo = "650102";
    }
    this.$nextTick(() => {
      this.selectArea = [this.tripDriver.provinceNo, this.tripDriver.cityNo, this.tripDriver.distinctNo];
      console.log(this.selectArea);
    })

  },
  methods: {
    focus() {
      this.remoteLoadPlate("");
    },
    ...mapMutations({
      close: "mutationCloseTag",
    }),
    gotoTripCarForm() {
      navTo(this, "/saveTripCar");
    },
    submitForm() {
      let that = this;
      this.$refs["form"].validate((valid) => {
        if (!valid) return;
        if (that.submitState) return;
        that.submitState = true;
        if (that.isLongTermValid) that.tripDriver.validateTime = "长期";
        if (that.idCardIsLongTermValid) {
          that.tripDriver.validateTime = "长期"
          that.tripDriver.certValidityType = 1;
        } else {
          // that.tripDriver.certBeginDate = that.tripDriver.idCardValidateTime[0];
          // that.tripDriver.certEndDate = that.tripDriver.idCardValidateTime[1];
          that.tripDriver.certValidityType = 0;
        }
        this.tripDriver.id > 0
          ? this.updateTripDriverById()
          : this.addTripDriver();
      });
    },
    cancel() {
      this.close({
        path: "/saveTripDriver",
      });
      this.$router.push("/TripDriverList");
    },
    addTripDriver() {
      TripDriverApi.saveTripDriver(this.tripDriver).then((resp) => {
        this.submitState = false;
        if (resp.code === '200') {
          this.tripDriver = {};
          this.cancel();
          this.$successMsg(resp.msg);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    updateTripDriverById() {
      TripDriverApi.updateTripDriver(this.tripDriver).then((resp) => {
        this.submitState = false;
        if (resp.code === '200') {
          this.user = {};
          this.cancel();
          this.$successMsg(resp.msg);
        } else {
          this.$errorMsg(resp.msg);
        }
      });
    },
    handleAvatarSuccess(res, file) {
      let data = file.response.data;
      console.log(data);
      this.$set(this.tripDriver, "imgPath", data.path);
      this.preImage = data.url;
      this.$refs.form.validateField("imgPath");
      this.ocrRecognizeDriverLicense(data.url);
    },
    handleIdCardFrontSuccess(res, file) {
      let data = file.response.data;
      console.log(data);
      // debugger
      this.$set(this.tripDriver, "idCardFront", data.path);
      this.tripDriver.idCardFront = data.url;
      this.tripDriver.idCardFrontFileId = data.fileId;

      this.$refs.form.validateField("idCardFront");
      //华为云定义国徽面为反面
      this.ocrRecognizeIdCard({ side: "back", url: data.url })
    },
    handleIdCardContrySuccess(res, file) {
      let data = file.response.data;
      console.log(data);
      this.$set(this.tripDriver, "idCardContrary", data.path);
      this.tripDriver.idCardContrary = data.url;
      this.tripDriver.idCardContraryFileId = data.fileId;
      this.$refs.form.validateField("idCardContrary");
      //华为云定义国徽面为反面
      this.ocrRecognizeIdCard({ side: "front", url: data.url })
    },
    beforeAvatarUpload(file) {
      let types = ["jpg", "png"];
      let ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      const isLt10M = file.size / 1024 / 1024 < 10;
      const isImage = types.includes(ext.toLowerCase());
      if (!isImage) {
        this.$message.error("上传图片只能是 jpg、png 格式!");
      }
      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isImage && isLt10M;
    },
    beforeAvatarUpload1(file) {
      let types = ["jpeg", "jpg", "png"];
      let ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      const isLt10M = file.size / 1024 / 1024 < 10;
      const isImage = types.includes(ext.toLowerCase());
      if (!isImage) {
        this.$message.error("上传图片只能是 jpg、png、jpeg 格式!");
      }
      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isImage && isLt10M;
    },

    queryDriverLicense() {
      TripDictionaryApi.queryDriverLicense().then((resp) => {
        if (resp.code === '200') {
          this.drivingType = resp.data;
        }
      });
    },
    remoteLoadPlate(query) {
      this.plateLoading = true;
      TripDriverApi.searchCar({ carNum: query })
        .then((resp) => {
          this.plateLoading = false;
          console.log(resp.data);
          if (resp.code === '200') {
            this.carList = resp.data;
            return;
          }
          this.carList = [];
        })
        .catch((error) => {
          this.$errorMsg(error);
          this.plateLoading = false;
          this.carList = [];
        });
    },
    tripDriverImgShow(path) {
      this.dialogImageUrl = path;
      this.dialogVisible = true;
    },
    handleRemove() {
      this.preImage = "";
      this.tripDriver.imgPath = "";
    },

    handleIdCardFrontRemove() {
      this.idCardFrontImage = "";
      this.tripDriver.idCardFront = "";
    },

    handleIdCardContrayRemove() {
      this.idCardContraryImage = "";
      this.tripDriver.idCardContrary = "";
    },
    areaChange(obj) {
      if (obj.length == 3) {
        this.tripDriver.provinceNo = obj[0];
        this.tripDriver.cityNo = obj[1];
        this.tripDriver.distinctNo = obj[2];
        // this.$set(this.tripDriver, "distinctCode", obj[2]);
        this.selectArea = obj;
        this.$refs.form.validateField("distinctNo");
      }
    },
    ocrRecognizeIdCard(params) {
      ocrApi.recognizeIdCard(params)
        .then((resp) => {
          console.log(resp.data);
          if (resp.code === '200') {
            if (params.side == "front") {
              this.$set(this.tripDriver, 'name', resp.data.name ?? this.tripDriver.name);
              this.$set(this.tripDriver, 'nativePlace', resp.data.nativePlace ?? this.tripDriver.nativePlace);
              this.$set(this.tripDriver, 'idCard', resp.data.idCard ?? this.tripDriver.idCard);
              this.$set(this.tripDriver, 'birthday', resp.data.birthdayStr ?? this.tripDriver.birthday);
            } else {
              this.$set(this.tripDriver, 'certBeginDate', resp.data.certBeginDateStr ?? this.tripDriver.certBeginDate);
              this.$set(this.tripDriver, 'certEndDate', resp.data.certEndDateStr ?? this.tripDriver.certEndDate);
              this.$set(this.tripDriver, 'isLongTermValid', resp.data.certValidityType ?? this.tripDriver.isLongTermValid);
            }
          }
          else {
            this.$errorMsg(resp.msg);
          }
        })
        .catch((error) => {
          this.$errorMsg(error);
        });
    },
    ocrRecognizeDriverLicense(url) {
      ocrApi.recognizeDriverLicense({ url: url })
        .then((resp) => {
          console.log(resp.data);
          if (resp.code === '200') {
            this.$set(this.tripDriver, 'firstApplyTime', resp.data.carFirstApplyTimeStr ?? this.tripDriver.firstApplyTime);
            if (resp.data.carModel) {
              let val = this.drivingType.filter(p => resp.data.carModel.includes(p.code));
              if (val.length > 0) {
                this.$set(this, 'selectCarTypeList', val.map(p => p.code));
              }
            }
            this.$set(this.tripDriver, 'validateTime', resp.data.carValidateTime ?? this.tripDriver.validateTime);

          }
          else {
            this.$errorMsg(resp.msg);
          }
        })
        .catch((error) => {
          this.$errorMsg(error);
        });
    }
  },
  // watch:{
  //   "tripDriver.idCardValidateTime":{
  //       handler(newVal,oldVal){
  //         console.log(newVal)
  //         console.log(oldVal)
  //       },
  //       deep:true,
  //       immediate:true
  //   }
  // }
};
</script>
  
  
<style lang="scss" scoped>
.head {
  line-height: 40px;
  font-weight: 600;
  font-size: 16px;
  margin: 0 32px 0px 32px;

  i {
    display: inline-block;
    width: 4px;
    height: 16px;
    background: #2C6AFF;
    margin-right: 8px;
    margin-bottom: -2px;
  }

  ::v-deep .el-button {
    margin: 0 8px;

  }
}

::v-deep .el-row {
  display: block;
  margin: 8px 32px;
}

.operate {
  width: 250px;
  margin: 56px auto 0 auto;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  background: #fafafa;
  border: 1px dashed #dddddd;
  width: 160px;
  height: 90px;
  line-height: 70px;
  text-align: center;


}

.avatar-uploader {
  .el-icon-plus {
    color: #2C6AFF;
  }
}

.avatar {
  width: 160px;
  height: 90px;
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px #f5f5f5 solid;

  img {
    width: 100%;
    height: 100%;
  }

  span {
    width: 0;
    height: 0;
    right: 0;
    top: 40px;
    display: inline-block;
    margin: 0;
    border-top: 40px solid red;
    border-left: 40px solid transparent;
    cursor: pointer;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;

    i {
      color: white;
      position: absolute;
      left: -18px;
      top: -32px;
    }
  }
}

.desc {
  color: #999999;
  display: flex;
  vertical-align: bottom;
}

.avatar:hover span {
  opacity: 1;
}

::v-deep .uploader .el-form-item__error {
  top: 134px;
  left: 0px;
  font-size: 12px;
}

::v-deep .el-input,
.el-select {
  width: 100%;
}

.avatar:hover span {
  opacity: 1;
}

.avatar-uploader {
  display: inline-block;

  span {
    width: 100%;
    height: 20px;
    display: inline-block;
    position: relative;
    top: -50px;
    color: #666666 !important;
    font-size: 14px;
  }
}

.text {
  position: absolute;
  margin-left: 10px;
  color: #ccc;
  font-size: 12px;
  margin: 50px auto 0 8px;
}

.color {
  color: red;
}

::v-deep .idCardTime {

  .idCardDateTime {
    width: 100%;
    display: flex;
    justify-content: center;

    .el-input {
      width: 50%;
      border: 0;

    }

    >span {
      width: 24px;
      text-align: center;
    }
  }
}

.uploaderList {
  margin: 6px 32px 0px 32px;
  height: 145px;
  display: flex;
  justify-content: flex-start;

  .uploader {
    width: 160px;
    margin: 0 32px 0 0;
    height: 90px;
  }
}

::v-deep .el-form-item {
  margin-bottom: 4px;

  .el-form-item__label {
    color: #666666;
  }
}

::v-deep .el-select__tags {
  flex-wrap: unset;
  overflow: hidden;
}
</style>